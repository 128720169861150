import React from 'react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from './App';
import { store } from './redux/store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d14ec5eda1a687cc7efdcc45cfeed2e9@o4506377141682176.ingest.us.sentry.io/4507107018932224',
  });
  axios.defaults.baseURL = 'https://app.clean-car.net';
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
