import { api } from './api';
import { API_ENDPOINTS } from '../../config';
import {
  IEmailConfirmData,
  IEmailData,
  IResponseCities,
  IResponseCount,
  IResponseFinance,
  IResponseLoginData,
  IResponseProfile,
  IResponseDetail,
  ISigninInputs,
  ISignupInputs,
  IResetPasswordConfirm,
  ITokenVerify,
  IResponseRefresh,
  IMiddleNameInputs,
} from '../../screens/types';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IResponseLoginData, ISigninInputs>({
      query: (userData) => ({
        url: API_ENDPOINTS.SIGNIN_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    signup: builder.mutation<IResponseDetail, ISignupInputs>({
      query: (userData) => ({
        url: API_ENDPOINTS.SIGNUP_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: API_ENDPOINTS.LOGOUT_ENDPOINT,
        method: 'POST',
        body: {},
      }),
    }),
    changePassword: builder.mutation<void, void>({
      query: () => ({
        url: API_ENDPOINTS.PASSWORD_CHANGE_ENDPOINT,
        method: 'POST',
        body: {},
      }),
    }),
    resetPassword: builder.mutation<IResponseDetail, IEmailData>({
      query: (userData) => ({
        url: API_ENDPOINTS.PASSWORD_RESET_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    resetPasswordConfirm: builder.mutation<IResponseDetail, IResetPasswordConfirm>({
      query: (userData) => ({
        url: API_ENDPOINTS.PASSWORD_RESET_CONFIRM_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    emailConfirm: builder.mutation<IResponseDetail, IEmailConfirmData>({
      query: (userData) => ({
        url: API_ENDPOINTS.EMAIL_CONFIRM_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    resendEmailConfirm: builder.mutation<IResponseDetail, IEmailData>({
      query: (userData) => ({
        url: API_ENDPOINTS.RESEND_EMAIL_CONFIRM_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    changeMiddleName: builder.mutation<void, IMiddleNameInputs>({
      query: (userData) => ({
        url: API_ENDPOINTS.CHANGE_MIDDLENAME_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    current: builder.query<void, ITokenVerify>({
      query: (userData) => ({
        url: API_ENDPOINTS.TOKEN_VERIFY_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    refresh: builder.query<IResponseRefresh, ITokenVerify>({
      query: (userData) => ({
        url: API_ENDPOINTS.TOKEN_REFRESH_ENDPOINT,
        method: 'POST',
        body: userData,
      }),
    }),
    city: builder.query<IResponseCities, void>({
      query: () => ({
        url: API_ENDPOINTS.CITIES_ENDPOINT,
        method: 'GET',
      }),
    }),
    getProfile: builder.query<IResponseProfile, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_USER_ENDPOINT,
        method: 'GET',
      }),
    }),
    getFinance: builder.query<IResponseFinance, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_USER_FINANCE_ENDPOINT,
        method: 'GET',
      }),
    }),
    getCount: builder.query<IResponseCount, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_USER_TASK_COUNT_ENDPOINT,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useEmailConfirmMutation,
  useResendEmailConfirmMutation,
  useChangeMiddleNameMutation,
  useCurrentQuery,
  useGetProfileQuery,
  useGetFinanceQuery,
  useGetCountQuery,
  useCityQuery,
} = authApi;

export const {
  endpoints: { login, signup, current, refresh },
} = authApi;
