import { createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../redux/services/auth';
import { RootState } from '../../redux/store';
import { IResponseLoginData } from '../../screens/types';

const initialState: IResponseLoginData = {
  access: null,
  refresh: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state: IResponseLoginData, action) => {
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
        state.isAuthenticated = true;
      })
      .addMatcher(authApi.endpoints.current.matchFulfilled, (state: IResponseLoginData) => {
        state.isAuthenticated = true;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state: IResponseLoginData) => {
        state.isAuthenticated = false;
      });
  },
});

export const { logout } = slice.actions;
export default slice.reducer;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;

export const selectUser = (state: RootState) => state.auth.access;
