import {
  alpha,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Fab,
  ImageList,
  ImageListItem,
  Input,
  MobileStepper,
  Modal,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { MdRemoveDone } from 'react-icons/md';
import { useGeolocated } from 'react-geolocated';
import { LuCamera, LuCheckCheck, LuChevronLeft, LuChevronRight, LuRefreshCcw, LuTrash } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetNavigationTaskQuery,
  useGetOpenTaskQuery,
  usePostDeleteImageMutation,
  usePostUploadImageMutation,
  useUpdateTaskMutation,
} from '../../redux/services/tasks';
import { IResponseNavigation, TPhoto, TTask } from '../types';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IQuizListInterface {
  id?: string;
  task: TTask;
  value: string | undefined;
  valueComment: string | undefined;
  activeStep: number;
  refetchTask: () => {};
  refetchNavigation: () => {};
  navigation: IResponseNavigation;
}

const QuizListComponent: React.FC<IQuizListInterface> = ({
  id,
  task,
  value,
  valueComment,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation();
  const [carConditionComment, setCarConditionComment] = React.useState(valueComment);

  const handleChangeValue = async (value: string) => {
    toast
      .promise(taskUpdate({ key: 'car_condition', value: value, id: id }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статус обновлен 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const handleChangeInput = async (value: string) => {
    toast
      .promise(taskUpdate({ key: 'car_condition_comment', value: value, id: id }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статус обновлен 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const validateString = async (value: string | null) => {
    if (value === null) return;
    await handleChangeValue(value);
  };

  const validateInput = async (value: string | null) => {
    if (value === null) return;
    await handleChangeInput(value);
  };

  const getOptions = () => {
    if (task.carsharing === 'ГАЗ' && task.type === 'type_gaz_acceptance') {
      return [
        { id: 'ok', label: 'Все ОК' },
        { id: 'comments', label: 'Есть замечания' },
        { id: 'not_receive', label: 'Получить не смогли' },
      ];
    } else {
      return [
        { id: 'ok', label: 'Все ОК' },
        { id: 'comments', label: 'Есть замечания' },
        { id: 'out_of_order', label: 'Автомобиль неисправен' },
      ];
    }
  };

  const getTitle = (id: string | undefined | { id: string; label: string }) => {
    if (typeof id === 'undefined' || id === '') return 'Выберите значение';
    if (id === 'ok') return 'Все ОК';
    if (id === 'comments') return 'Есть замечания';
    if (id === 'not_receive') return 'Получить не смогли';
    if (id === 'out_of_order') return 'Автомобиль неисправен';
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      {['car_condition'].includes(navigation.stages[activeStep].serializer_name) && (
        <>
          <Autocomplete
            id="car_condition"
            freeSolo
            size="small"
            fullWidth
            options={getOptions()}
            onChange={(event, newValue) => {
              validateString(typeof newValue !== 'string' && newValue !== null ? newValue?.id : '');
            }}
            onBlur={(e) => {
              validateString((e.target as HTMLInputElement).value);
            }}
            renderInput={(params) => <TextField {...params} label={getTitle(value)} />}
          />
          {['comments', 'not_receive', 'out_of_order'].includes(typeof value === 'undefined' ? '' : value) && (
            <TextField
              data-cy="input-vin-number"
              label={'Комментарий'}
              value={carConditionComment}
              margin="normal"
              sx={{
                mt: 1,
              }}
              fullWidth
              size="small"
              onChange={(e) => {
                setCarConditionComment(e.target.value);
              }}
              onBlur={(e) => {
                validateInput(e.target.value);
              }}
            />
          )}
        </>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

interface IInputListInterface {
  id?: string;
  value: TPhoto[] | undefined | number | string;
  activeStep: number;
  refetchTask: () => {};
  refetchNavigation: () => {};
  navigation: IResponseNavigation;
}

const AutocompleteListComponent: React.FC<IInputListInterface> = ({
  id,
  value,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation();

  const handleChangeValue = async (value: string) => {
    toast
      .promise(taskUpdate({ key: navigation.stages[activeStep].serializer_name, value: value, id: id }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статус обновлен 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const validateString = async (value: string | null) => {
    if (value === null) return;
    await handleChangeValue(value);
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      {['transit_season_tire_before', 'transit_season_tire_after'].includes(
        navigation.stages[activeStep].serializer_name,
      ) && (
        <Autocomplete
          id="transit_season_tire_before_or_after"
          freeSolo
          size="small"
          fullWidth
          options={navigation.stages[activeStep].autocomplete_options.map((option) => option)}
          onChange={(event, newValue) => {
            validateString(newValue);
          }}
          onBlur={(e) => {
            validateString((e.target as HTMLInputElement).value);
          }}
          renderInput={(params) => <TextField {...params} label={value?.toString()} />}
        />
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

const InputListComponent: React.FC<IInputListInterface> = ({
  id,
  value,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation();

  if (typeof value !== 'number') {
    return;
  }

  const handleChangeValue = async (value: string) => {
    toast
      .promise(taskUpdate({ key: navigation.stages[activeStep].serializer_name, value: value, id: id }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статус обновлен 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const validateNumber = async (value: string) => {
    if (isNaN(Number(value))) return;
    if (Number(value) <= 0) return;
    await handleChangeValue(value);
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      {['mileage_before_task', 'mileage_after_task', 'antifreeze_liquid_liters_new'].includes(
        navigation.stages[activeStep].serializer_name,
      ) && (
        <TextField
          label={value.toString()}
          type={'number'}
          margin="normal"
          sx={{ mt: 0 }}
          fullWidth
          size="small"
          onBlur={(e) => validateNumber(e.target.value)}
        />
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

interface IImageListInterface {
  id?: string;
  activeStep: number;
  refetchTask: () => {};
  refetchNavigation: () => {};
  navigation: IResponseNavigation;
  photosList: TPhoto[] | undefined | number | string;
  setImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImagesListComponent: React.FC<IImageListInterface> = ({
  id,
  photosList,
  navigation,
  activeStep,
  refetchTask,
  setImageLoading,
  refetchNavigation,
}) => {
  const [pickedImage, setPickedImage] = React.useState<string[]>([]);
  const [isRequiredVisible, setIsRequiredVisible] = React.useState(true);
  const [openDeletePhotoModal, setOpenDeletePhotoModal] = React.useState(false);
  const [postUploadImage, postUploadImageResult] = usePostUploadImageMutation();
  const [postDeleteImage, postDeleteImageResult] = usePostDeleteImageMutation();
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: 30000,
    },
  });

  React.useEffect(() => {
    setIsRequiredVisible(pickedImage.length === 0);
  }, [pickedImage]);

  if (typeof photosList === 'number' || typeof photosList === 'undefined' || typeof photosList === 'string') {
    return;
  }

  if (!isGeolocationAvailable) {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ p: 3, textAlign: 'center' }}>
          На данном устройстве нельзя получить доступ к геопозиции. Без предоставления доступа у Вас нет возможности
          загружать фотографии в задачу.
        </Typography>
      </Box>
    );
  }

  if (!isGeolocationEnabled) {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ p: 3, textAlign: 'center' }}>
          Необходимо разрешить сайту доступ к геопозиции устройства. Без предоставления доступа у Вас нет возможности
          загружать фотографии в задачу. Позвольте данному сайту доступ к геопозиции в настройках вашего устройства.
        </Typography>
      </Box>
    );
  }

  const handleOpen = () => setOpenDeletePhotoModal(true);
  const handleClose = () => setOpenDeletePhotoModal(false);

  const onPickImage = (e: React.MouseEvent<HTMLImageElement>) => {
    setPickedImage((oldArray) => {
      let newArray = [...oldArray];
      if (newArray.includes((e.target as HTMLImageElement).id)) {
        const index = newArray.indexOf((e.target as HTMLImageElement).id);
        if (index !== -1) {
          newArray.splice(index, 1);
        }
        return [...newArray];
      } else {
        return [...oldArray, (e.target as HTMLImageElement).id];
      }
    });
  };

  const decCache: number[] = [];
  const decCases = [2, 0, 1, 1, 1, 2];

  const decOfNum = (number: number, titles: string[]): string => {
    if (!decCache[number])
      decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
    return titles[decCache[number]];
  };

  const imageOrientation = (image: string) => {
    var img = new Image();
    img.src = image;
    return { width: img.naturalWidth, height: img.naturalHeight };
  };

  const compressImage = async (image: File) => {
    const options = {
      fileType: 'image/jpeg',
      maxWidthOrHeight: 1280,
    };

    const compressedFile = await imageCompression(image, options);
    let compress_file = await compressedFile;
    return await compress_file;
  };

  const createImageFormData = async (image: File) => {
    if (!coords) return;
    if (!id) return;
    let datetime = new Date();
    const dt = new Date(datetime);
    const year = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = dt.getDate().toString().padStart(2, '0');
    const hour = dt.getHours().toString().padStart(2, '0');
    const minute = dt.getMinutes().toString().padStart(2, '0');
    const seconds = dt.getSeconds().toString().padStart(2, '0');
    const ms = dt.getMilliseconds().toString().padStart(2, '0');
    let formData = new FormData();
    const newName = id + '__' + `${day}-${month}-${year}__${hour}-${minute}-${seconds}-${ms}` + '.jpeg';
    const newFile = new File([image], newName);
    formData.append('task', id);
    formData.append('image', newFile);
    formData.append('filename', newName);
    formData.append('photo_longitude', coords?.longitude.toFixed(6).toString());
    formData.append('photo_latitude', coords?.latitude.toFixed(6).toString());
    return formData;
  };

  const handleUploadImages = async (images?: FileList | null) => {
    if (!images) return;
    setImageLoading(true);
    for (let i = 0; i < images.length; i++) {
      let imageData = await compressImage(images[i]);
      let imageFormData = await createImageFormData(imageData);
      if (!imageFormData) return;
      await toast.promise(
        postUploadImage({ apiLink: navigation.stages[activeStep].api_link, formData: imageFormData })
          .unwrap()
          .catch((err) => {
            Sentry.captureException(err);
          }),
        {
          success: 'Фотография успешно загружена 👌',
          error: 'Ошибка при загрузке фотографии 🤯',
        },
      );
    }
    setImageLoading(false);
    refetchTask();
  };

  const handleDeleteImages = async () => {
    toast
      .promise(postDeleteImage({ ids: pickedImage, apiLink: navigation.stages[activeStep].api_link }).unwrap(), {
        success: 'Фотография успешно удалена 👌',
        error: 'Ошибка при удалении фотографии 🤯',
      })
      .then(() => {
        setOpenDeletePhotoModal(false);
        refetchTask();
      });
  };

  return (
    <>
      <ImageList
        sx={{
          mt: 0,
          mb: 0,
        }}
        component={'div'}
      >
        {typeof photosList !== 'undefined' &&
          photosList.map((item) => (
            <ImageListItem key={item.id} sx={{ aspectRatio: '1' }}>
              <img
                id={item.id.toString()}
                srcSet={`${item.image}`}
                src={`${item.image}`}
                alt={item.filename}
                loading="lazy"
                style={{ aspectRatio: 1 }}
                onClick={(e) => onPickImage(e)}
              />
              {pickedImage?.includes(item.id.toString()) && (
                <Box sx={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                  <LuCheckCheck color={'red'} size={20} />
                </Box>
              )}
            </ImageListItem>
          ))}
      </ImageList>
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      {pickedImage.length !== 0 && (
        <>
          <Box sx={{ position: 'fixed', bottom: '75px', left: 'calc(50% - 75px)' }}>
            <Fab
              component="span"
              onClick={() => {
                setPickedImage([]);
              }}
              color="primary"
            >
              <MdRemoveDone size={24} />
            </Fab>
          </Box>
          <Box sx={{ position: 'fixed', bottom: '75px', right: 'calc(50% - 75px)' }}>
            <Fab
              component="span"
              onClick={() => {
                handleOpen();
              }}
              color="primary"
            >
              <LuTrash size={24} />
            </Fab>
          </Box>
        </>
      )}
      {isRequiredVisible && (
        <Box sx={{ position: 'fixed', bottom: '75px', right: 'calc(50% - 28px)' }}>
          <Fab component="span" color="primary" onClick={() => toast.info('Минимальное количество фотографий')}>
            <Typography>{navigation.stages[activeStep].min_photo}</Typography>
          </Fab>
        </Box>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <input
          accept="image/*"
          id="raised-button-file"
          type="file"
          multiple
          hidden
          onChange={(e) => {
            const sellectFile = e.target.files ? e.target.files : null;
            handleUploadImages(sellectFile);
          }}
        />
        <label htmlFor="raised-button-file">
          <Fab component="span" color="primary">
            <LuCamera size={24} />
          </Fab>
        </label>
      </Box>
      <Modal
        open={openDeletePhotoModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95%',
            maxWidth: '552px',
            bgcolor: 'background.paper',
            border: 'none',
            borderRadius: '8px',
            boxShadow: 24,
            p: 2,
            pt: 1,
            pb: 1,
          }}
        >
          <h3 id="modal-title" style={{ textAlign: 'center' }}>
            Удалить {decOfNum(pickedImage.length, ['фотографию', 'фотографии', 'фотографии'])}
          </h3>
          <p id="modal-description" style={{ textAlign: 'center' }}>
            Вы действительно хотите удалить {pickedImage.length}{' '}
            {decOfNum(pickedImage.length, ['фотографию', 'фотографии', 'фотографий'])}?
          </p>
          <Box sx={{ mt: 2, mb: 2 }}>
            {postDeleteImageResult.isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '40px' }}>
                <CircularProgress size={26} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', height: '40px' }}>
                <Button onClick={() => handleClose()} sx={{ mr: 2 }}>
                  Отменить
                </Button>
                <Button onClick={() => handleDeleteImages()} sx={{ ml: 2 }} color="error">
                  Удалить
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

interface ITaskInterface {
  setInfo: React.Dispatch<React.SetStateAction<TTask | undefined>>;
}

export const TaskComponent: React.FC<ITaskInterface> = ({ setInfo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const task = useGetOpenTaskQuery(id!);
  const [maxStep, setMaxStep] = React.useState(0);
  const navigation = useGetNavigationTaskQuery(id!);
  const [activeStep, setActiveStep] = React.useState(0);
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [imageLoading, setImageLoading] = React.useState(false);
  const [taskUpdate, taskUpdateResult] = useUpdateTaskMutation();

  React.useEffect(() => {
    setInfo(undefined);
    if (typeof task.data !== 'undefined') {
      if (task.data?.carsharing == 'ГАЗ') {
        if (task.data?.type_display == 'transit') {
          navigate('/');
          toast.info('Задачи "ГАЗ" доступны в приложении.');
        }
      }
      setInfo(task.data);
      document.title = `${task.data.car_model.length >= 17 ? `${task.data.car_model.slice(0, 14)}...` : task.data.car_model} [${task.data.car_plate?.toLocaleUpperCase() || task.data.vin_number.toLocaleUpperCase().slice(task.data.vin_number.length - 10, task.data.vin_number.length - 1)}] | КлинКар`;
    }
  }, [task]);

  React.useEffect(() => {
    if (typeof navigation.data !== 'undefined') {
      setMaxStep(navigation.data.stages.length);
      if (navigation.data.stages.length === 0) {
        setMaxStep(1);
      }
    }
  }, [navigation]);

  if (
    navigation.isFetching ||
    task.isFetching ||
    imageLoading ||
    typeof task.data === 'undefined' ||
    typeof navigation.data === 'undefined'
  ) {
    return (
      <Box sx={{ display: 'flex', height: `calc(100dvh - ${matchesSm ? 64 : 56}px)`, flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 4 }}>Идет загрузка данных</Typography>
          <Typography sx={{ mb: 4 }}>Пожалуйста, подождите</Typography>
        </Box>
      </Box>
    );
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === maxStep - 1) {
      handleComplete();
    } else if (maxStep === 0) {
      handleComplete();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleComplete = async () => {
    toast
      .promise(taskUpdate({ key: 'status', value: 'completed', id: id }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Задача завершена 👌',
        error: 'Ошибка при завершении задачи 🤯',
      })
      .then(() => {
        navigate('/tasks/');
      });
  };

  const isPrevButtonDisable = (): boolean => {
    if (activeStep !== 0) {
      return false;
    }
    return true;
  };

  const isNextButtonDisable = (): boolean => {
    if (task.data) {
      if (navigation.data) {
        if (navigation.data.stages.length !== 0) {
          if (navigation.data.stages[activeStep].photo_stage) {
            if (
              navigation.data.stages[activeStep].min_photo <=
              (task.data[navigation.data.stages[activeStep].serializer_name] as TPhoto[]).length
            ) {
              return false;
            }
          }
          if (navigation.data.stages[activeStep].input_stage) {
            if (navigation.data.stages[activeStep].require) {
              if (task.data[navigation.data.stages[activeStep].serializer_name] === 0) {
                return true;
              }
            }
            return false;
          }
          if (navigation.data.stages[activeStep].autocomplete_stage) {
            if (navigation.data.stages[activeStep].require) {
              if (task.data[navigation.data.stages[activeStep].serializer_name] === null) {
                return true;
              }
            }
            return false;
          }
          if (navigation.data.stages[activeStep].quiz_stage) {
            if (navigation.data.stages[activeStep].require) {
              if ([null, ''].includes(task.data[navigation.data.stages[activeStep].serializer_name].toString())) {
                return true;
              }
              if (
                ['comments', 'not_receive'].includes(
                  task.data[navigation.data.stages[activeStep].serializer_name].toString(),
                )
              ) {
                if ([null, ''].includes(task.data['car_condition_comment'])) {
                  return true;
                }
              }
            }
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <Container
      component="main"
      maxWidth={'sm'}
      sx={{
        pl: 0,
        pr: 0,
        flexGrow: 1,
        height: `calc(100dvh - ${matchesSm ? 64 : 56}px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          pt: 1,
          pb: 1,
          minHeight: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
          }}
        >
          {navigation.data.stages[activeStep]?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          bgcolor: `${alpha(theme.palette.text.primary, theme.palette.mode === 'light' ? 0.11 : 0.13)}`,
          pt: '4px',
          pb: '4px',
        }}
      >
        {/* {!navigation.data.stages && <></>} */}
        {navigation.data.stages[activeStep]?.photo_stage && (
          <ImagesListComponent
            id={task.data.id}
            activeStep={activeStep}
            refetchTask={task.refetch}
            navigation={navigation.data}
            setImageLoading={setImageLoading}
            refetchNavigation={navigation.refetch}
            photosList={task.data[navigation.data.stages[activeStep].serializer_name]}
          />
        )}
        {navigation.data.stages[activeStep]?.input_stage && (
          <InputListComponent
            id={task.data.id}
            activeStep={activeStep}
            refetchTask={task.refetch}
            navigation={navigation.data}
            refetchNavigation={navigation.refetch}
            value={task.data[navigation.data.stages[activeStep].serializer_name]}
          />
        )}
        {navigation.data.stages[activeStep]?.autocomplete_stage && (
          <AutocompleteListComponent
            id={task.data.id}
            activeStep={activeStep}
            refetchTask={task.refetch}
            navigation={navigation.data}
            refetchNavigation={navigation.refetch}
            value={task.data[navigation.data.stages[activeStep].serializer_name]}
          />
        )}
        {navigation.data.stages[activeStep]?.quiz_stage && (
          <QuizListComponent
            id={task.data.id}
            task={task.data}
            activeStep={activeStep}
            refetchTask={task.refetch}
            navigation={navigation.data}
            refetchNavigation={navigation.refetch}
            valueComment={task.data['car_condition_comment']}
            value={
              typeof task.data[navigation.data.stages[activeStep].serializer_name] === 'string'
                ? task.data[navigation.data.stages[activeStep].serializer_name].toString()
                : undefined
            }
          />
        )}
      </Box>
      <MobileStepper
        variant={maxStep > 4 ? 'progress' : 'dots'}
        steps={maxStep}
        position="static"
        activeStep={activeStep}
        sx={{
          pt: 2,
          pb: 2,
          height: '64px',
          textAlign: 'center',
        }}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={isNextButtonDisable() || taskUpdateResult.isLoading}>
            Далее
            <LuChevronRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={isPrevButtonDisable()}>
            <LuChevronLeft />
            Назад
          </Button>
        }
      />
    </Container>
  );
};
