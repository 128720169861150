import React from "react";

const PasswordInformationComponent = () => {
  return (
    <ul>
      <li>Пароль не должен быть слишком похож на другую вашу личную информацию.</li>
      <li>Ваш пароль должен содержать как минимум 8 символов.</li>
      <li>Пароль не должен быть слишком простым и распространенным.</li>
      <li>Пароль не может состоять только из цифр.</li>
    </ul>
  );
};

export default PasswordInformationComponent
