import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { API_ENDPOINTS } from '../../config';
import { RootState } from '../store';
import { toast } from 'react-toastify';
import { refresh } from './auth';

const getBaseUrl = (): string => {
  if (['http://localhost:3000', 'http://127.0.0.1:3000'].includes(window.location.origin)) {
    return 'http://127.0.0.1:8000';
  } else if (['http://192.168.31.236:3000'].includes(window.location.origin)) {
    return 'http://192.168.31.236:8000';
  } else {
    return window.location.origin.toString();
  }
};

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: `${getBaseUrl()}/api`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    try {
      const access = (getState() as RootState).auth.access || localStorage.getItem('access');

      if (
        access &&
        endpoint !== 'login' &&
        endpoint !== 'signup' &&
        endpoint !== 'changePassword' &&
        endpoint !== 'resetPassword' &&
        endpoint !== 'resetPasswordConfirm' &&
        endpoint !== 'emailConfirm' &&
        endpoint !== 'resendEmailConfirm' &&
        endpoint !== 'current' &&
        endpoint !== 'refresh'
      ) {
        headers.set('authorization', `Bearer ${access}`);
      }

      return headers;
    } catch (err) {
      return headers;
    }
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  // if (result.error && result.error.data? && result.error.data.detail && result.error.data.detail === 'Пользователь неактивен') {
  //   localStorage.removeItem('access');
  //   localStorage.removeItem('refresh');
  // }
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: API_ENDPOINTS.TOKEN_REFRESH_ENDPOINT,
            method: 'POST',
            body: {
              refresh: localStorage.getItem('refresh'),
            },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const refreshData = refreshResult.data as { access_expiration: string; access: string };
          localStorage.setItem('access', refreshData.access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
