import * as React from 'react';
import { alpha, Box, Container, Fab, Skeleton, Tab, Typography, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LuChevronRight, LuDelete, LuPlus, LuRefreshCcw, LuTrain, LuTrash } from 'react-icons/lu';
import { UI_URLS } from '../../config';
import { useGetCloseTasksQuery, useGetOpenTasksQuery } from '../../redux/services/tasks';
import { IResponseTasks, TTask } from '../types';
import delimobilLogo from '../../assets/icons/company_icons/delimobil128.png';
import citydriveLogo from '../../assets/icons/company_icons/citydrive128.png';
import rentCarsLogo from '../../assets/icons/company_icons/rentcars128.png';
import sberAutoLogo from '../../assets/icons/company_icons/sberauto128.png';
import invitroLogo from '../../assets/icons/company_icons/invitro128.png';
import gruzovichkofLogo from '../../assets/icons/company_icons/gruzovichkof128.png';
import testTorgLogo from '../../assets/icons/company_icons/testtorg128.png';
import gazLogo from '../../assets/icons/company_icons/gaz128.png';
import yandexLogo from '../../assets/icons/company_icons/yandexdrive128.png';

const OPEN_TASK_STATUS = 'open';
const COMPLETED_TASK_STATUS = 'complete';
const TASK_CARD_HEIGHT = 60;

const TaskCardComponent: React.FC<{ item: TTask }> = ({ item }) => {
  const theme = useTheme();

  return (
    <Box
      component={Link}
      to={`/task/${item.id}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: `${TASK_CARD_HEIGHT}px`,
        bgcolor: `${alpha(theme.palette.text.primary, theme.palette.mode === 'light' ? 0.11 : 0.13)}`,
        width: '100%',
        pl: 1,
        pr: 1,
        color: 'inherit',
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        textDecoration: 'none',
      }}
    >
      <Box
        sx={{
          width: `${TASK_CARD_HEIGHT - 20}px`,
          height: `${TASK_CARD_HEIGHT - 20}px`,
        }}
      >
        {item.carsharing === 'Делимобиль' && <img src={delimobilLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'СитиДрайв' && <img src={citydriveLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'RentCars' && <img src={rentCarsLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'СберАвтоподписка' && <img src={sberAutoLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'Инвитро' && <img src={invitroLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'Грузовичкоф' && <img src={gruzovichkofLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'ТестТорг' && <img src={testTorgLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'ГАЗ' && <img src={gazLogo} style={{ height: '100%', width: '100%' }} />}
        {item.carsharing === 'Яндекс' && <img src={yandexLogo} style={{ height: '100%', width: '100%' }} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '40%',
        }}
      >
        <Typography component="span">
          {item.car_model.length >= 17 ? `${item.car_model.slice(0, 14)}...` : item.car_model}
        </Typography>
        <Typography component="span">
          {item.car_plate?.toLocaleUpperCase() ||
            item.vin_number?.toLocaleUpperCase().slice(item.vin_number.length - 10, item.vin_number.length - 1)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '30%',
        }}
      >
        <Typography component="span">{item.type_display}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LuChevronRight size={24} />
      </Box>
    </Box>
  );
};

const TasksLoadingComponent: React.FC = () => {
  const theme = useTheme();
  const [quantity, setQuantity] = React.useState(0);

  React.useEffect(() => {
    // Количество блоков для установления скелетонов в размер экрана
    setQuantity(Math.floor(document.getElementById('main')!.clientHeight / TASK_CARD_HEIGHT) - 1);
  }, []);

  return (
    <>
      {[...Array(quantity)].map((item, iter) => (
        <Skeleton
          key={iter}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={`${TASK_CARD_HEIGHT}px`}
          sx={{ borderBottom: `1px solid ${theme.palette.text.secondary}` }}
        />
      ))}
    </>
  );
};

const TasksReadyComponent: React.FC<{ tasks: IResponseTasks }> = ({ tasks }) => {
  return (
    <>
      {tasks.results.map((item, iter) => (
        <TaskCardComponent key={item.id} item={item} />
      ))}
    </>
  );
};

interface ITasksProps {
  setBadge: React.Dispatch<React.SetStateAction<number>>;
}

const OpenTasksComponent: React.FC<ITasksProps> = (props) => {
  const { data, isFetching, refetch } = useGetOpenTasksQuery();

  React.useEffect(() => {
    document.title = 'Открытые задачи | КлинКар';
    refetch();
  }, []);

  React.useEffect(() => {
    if (data?.results) {
      props.setBadge(data?.results.length);
    }
  }, [data]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0, m: 0 }}>
      {isFetching ? (
        <>
          <TasksLoadingComponent />
        </>
      ) : (
        <>
          {typeof data !== 'undefined' ? (
            data?.results.length === 0 ? (
              <Typography sx={{ pt: 3 }}>Задачи не найдены</Typography>
            ) : (
              <TasksReadyComponent tasks={data} />
            )
          ) : (
            <Typography sx={{ pt: 3 }}>Задачи не найдены</Typography>
          )}
          <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
            <Fab
              color="primary"
              aria-label="create"
              data-cy="tasks-create-task"
              component={Link}
              to={UI_URLS.TASKS_CREATE_LINK}
            >
              <LuPlus size={24} />
            </Fab>
          </Box>
        </>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          color="primary"
          onClick={() => {
            refetch();
          }}
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

const CloseTasksComponent: React.FC = () => {
  const { data, isFetching, refetch } = useGetCloseTasksQuery();

  React.useEffect(() => {
    document.title = 'Закрытые задачи | КлинКар';
    refetch();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0, m: 0 }}>
      {isFetching ? (
        <>
          <TasksLoadingComponent />
        </>
      ) : (
        <>
          {typeof data !== 'undefined' ? (
            data?.results.length === 0 ? (
              <Typography sx={{ pt: 3 }}>Задачи не найдены</Typography>
            ) : (
              <TasksReadyComponent tasks={data} />
            )
          ) : (
            <Typography sx={{ pt: 3 }}>Задачи не найдены</Typography>
          )}
        </>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          color="primary"
          onClick={() => {
            refetch();
          }}
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

interface ITasksProps {
  setBadge: React.Dispatch<React.SetStateAction<number>>;
}

const TasksComponent: React.FC<ITasksProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(OPEN_TASK_STATUS);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (window.location.search.includes(`search=${OPEN_TASK_STATUS}`)) {
      setValue(OPEN_TASK_STATUS);
    } else if (window.location.search.includes(`search=${COMPLETED_TASK_STATUS}`)) {
      setValue(COMPLETED_TASK_STATUS);
    } else {
      setValue(OPEN_TASK_STATUS);
      setSearchParams((searchParams) => {
        searchParams.set('search', OPEN_TASK_STATUS);
        return searchParams;
      });
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams((searchParams) => {
      searchParams.set('search', newValue);
      return searchParams;
    });
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ flexGrow: 1, p: 0, pb: 8 }} id="main">
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            bgcolor: `${theme.palette.background.default}`,
            zIndex: 1,
          }}
        >
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            centered
            indicatorColor={'primary'}
            textColor={'inherit'}
          >
            <Tab label="Открытые" value={OPEN_TASK_STATUS} />
            <Tab label="Закрытые" value={COMPLETED_TASK_STATUS} />
          </TabList>
        </Box>
        <TabPanel value="open" sx={{ p: 0, m: 0 }}>
          <OpenTasksComponent setBadge={props.setBadge} />
        </TabPanel>
        <TabPanel value="complete" sx={{ p: 0, m: 0 }}>
          <CloseTasksComponent />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default TasksComponent;
