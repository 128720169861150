import { Box, Breadcrumbs, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';
import { emailValidation } from '../form-validation';
import { IEmailData } from '../types';
import { useResendEmailConfirmMutation } from '../../redux/services/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { isErrorWithData } from '../is-error-with-message';

export const DeleteAccountComponent = () => {
  const [resendEmailConfirm, resendEmailConfirmResult] = useResendEmailConfirmMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IEmailData>({
    defaultValues: {
      email: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Удаление аккаунта | КлинКар';
  }, []);

  const onSubmit: SubmitHandler<IEmailData> = (data) => {
    resendEmailConfirmQuery(data);
  };

  const resendEmailConfirmQuery = async (data: IEmailData) => {
    try {
      await resendEmailConfirm(data)
        .unwrap()
        .then(() => {
          toast.success('Аккаунт удалён');
        });
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNIN_LINK}>
            Вход
          </Link>
          <Typography color="text.primary">Удаление аккаунта</Typography>
        </Breadcrumbs>
        <Typography color="text.primary" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
          Если Вы хотите удалить ваш аккаунт, вам необходимо заполнить адрес электронной почты
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={emailValidation}
            render={({ field }) => (
              <TextField
                label="Почта"
                margin="normal"
                size="small"
                fullWidth
                autoComplete="email"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
          <LoadingButton
            type="submit"
            loading={resendEmailConfirmResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Удалить
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};
