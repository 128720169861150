import * as React from 'react';
import { Box, Breadcrumbs, Fab, Fade, Link, Toolbar, useScrollTrigger } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { LuChevronUp } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

const ScrollTop = (props: Props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Fade>
  );
};

const PrivacyPolicyEnComponent: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    document.title = 'Политика обработки данных | КлинКар';
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNUP_LINK}>
            Signup
          </Link>
          <Typography color="text.primary">Privacy Policy</Typography>
        </Breadcrumbs>
        <Box
          sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'justify' }}
        >
          <span>Updated: 11/23/2023</span>
          <span>
            <strong>
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
              information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </strong>
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
            collection and use of information in accordance with this Privacy Policy.
          </span>
          <span>Interpretation</span>
          <span>
            The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.
          </span>
          <span>Definitions</span>
          <span>For the purposes of this Privacy Policy:</span>
          <ol>
            <li>
              <strong>Account</strong> means a unique account created for You to access our Service or parts of our
              Service.
            </li>

            <li>
              <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with
              a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other managing authority.
            </li>
            <li>
              <strong>Application</strong> refers to Clean Car, the software program provided by the Company.
            </li>
            <li>
              <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
              &quot;Our&quot; in this Agreement) refers to Clean Car.
            </li>
            <li>
              <strong>Country</strong> refers to: Russia
            </li>
            <li>
              <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a
              digital tablet.
            </li>
            <li>
              <strong>Personal Data</strong> is any information that relates to an identified or identifiable
              individual.
            </li>
            <li>
              <strong>Service</strong> refers to the Application.
            </li>
            <li>
              <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
              the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to perform services related to the Service or to
              assist the Company in analyzing how the Service is used.
            </li>
            <li>
              <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
              Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
              entity on behalf of which such individual is accessing or using the Service, as applicable.
            </li>
          </ol>
          <span>Collecting and Using Your Personal Data</span>
          <span>Types of Data Collected</span>
          <span>Personal Data</span>
          <span>
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that
            can be used to contact or identify You. Personally identifiable information may include, but is not limited
            to:
          </span>
          <ol>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Usage Data</li>
          </ol>
          <span>Usage Data</span>
          <span>Usage Data is collected automatically when using the Service.</span>
          <span>
            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.
          </span>
          <span>
            When You access the Service by or through a mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
            address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </span>
          <span>
            We may also collect information that Your browser sends whenever You visit our Service or when You access
            the Service by or through a mobile device.
          </span>
          <span>Information Collected while Using the Application</span>
          <span>
            While using Our Application, in order to provide features of Our Application, We may collect, with Your
            prior permission
          </span>
          <ol>
            <li>Information regarding your location</li>
            <li>Pictures and other information from your Device's camera and photo library</li>
          </ol>
          <span>
            We use this information to provide features of Our Service, to improve and customize Our Service. The
            information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply
            stored on Your device.
          </span>
          <span>Information Collected while Using the Application</span>
          <span>You can enable or disable access to this information at any time, through Your Device settings.</span>
          <span>Use of Your Personal Data</span>
          <span>The Company may use Personal Data for the following purposes</span>
          <ol>
            <li>
              <strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
            </li>
            <li>
              <strong>To manage Your Account:</strong> To manage Your registration as a user of the Service. The
              Personal Data You provide can give You access to different functionalities of the Service that are
              available to You as a registered user.
            </li>
            <li>
              <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms
              of electronic communication, such as a mobile application's push notifications regarding updates or
              informative communications related to the functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their implementation.
            </li>
            <li>
              <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </li>
            <li>
              <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
              divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
              Personal Data held by Us about our Service users is among the assets transferred.
            </li>
            <li>
              <strong>For other purposes</strong> We may use Your information for other purposes, such as data analysis,
              identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and
              improve our Service, products, services, marketing and your experience.
            </li>
          </ol>
          <span>We may share Your personal information in the following situations</span>
          <ol>
            <li>
              <strong>With Service Providers:</strong> We may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You.
            </li>
            <li>
              <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will
              require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other
              subsidiaries, joint venture partners or other companies that We control or that are under common control
              with Us.
            </li>
            <li>
              <strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with
              Your consent.
            </li>
          </ol>
          <span>Retention of Your Personal Data</span>
          <span>
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
            disputes, and enforce our legal agreements and policies.
          </span>
          <span>
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
            a shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
          </span>
          <span>Transfer of Your Personal Data</span>
          <span>
            Your information, including Personal Data, is processed at the Company's operating offices and in any other
            places where the parties involved in the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
          </span>
          <span>
            Your consent to this Privacy Policy followed by Your submission of such information represents Your
            agreement to that transfer.
          </span>
          <span>
            The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of Your data and other
            personal information.
          </span>
          <span>Delete Your Personal Data</span>
          <span>
            You have the right to delete or request that We assist in deleting the Personal Data that We have collected
            about You.
          </span>
          <span>
            Our Service may give You the ability to delete certain information about You from within the Service.
          </span>
          <span>
            You may update, amend, or delete Your information at any time by signing in to Your Account, if you have
            one, and visiting the account settings section that allows you to manage Your personal information. You may
            also contact Us to request access to, correct, or delete any personal information that You have provided to
            Us.
          </span>
          <span>
            Please note, however, that We may need to retain certain information when we have a legal obligation or
            lawful basis to do so.
          </span>
          <span>Disclosure of Your Personal Data</span>
          <span>Business Transactions</span>
          <span>
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
            will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
            Policy.
          </span>
          <span>Law enforcement</span>
          <span>
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
            by law or in response to valid requests by public authorities (e.g. a court or a government agency).
          </span>
          <span>Other legal requirements</span>
          <span>
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
          </span>
          <ol>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ol>
          <span>Security of Your Personal Data</span>
          <span>
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
            means to protect Your Personal Data, We cannot guarantee its absolute security.
          </span>
          <span>Children's Privacy</span>
          <span>
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
            information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become aware that We have collected
            Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.
          </span>
          <span>
            If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.
          </span>
          <span>Links to Other Websites</span>
          <span>
            Our Service may contain links to other websites that are not operated by Us. If You click on a third party
            link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
            of every site You visit.
          </span>
          <span>
            We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.
          </span>
          <span>Changes to this Privacy Policy</span>
          <span>
            We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.
          </span>
          <span>
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
            effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.
          </span>
          <span>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </span>
          <span>Contact Us</span>
          <span>If you have any questions about this Privacy Policy, You can contact us:</span>
          <span>By email: cc@clean-car.net</span>
        </Box>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <LuChevronUp />
          </Fab>
        </ScrollTop>
      </Box>
    </Container>
  );
};

export default PrivacyPolicyEnComponent;
