import { api } from './api';
import { API_ENDPOINTS } from '../../config';
import {
  IDataPostDelete,
  IImageUpload,
  IOpenTask,
  IResponseCarsByCarsharingList,
  IResponseCarsharingList,
  IResponseFieldListByCarsharingAndTypes,
  IResponseFieldListToDrawer,
  IResponseNavigation,
  IResponseTasks,
  IResponseTaskTypesList,
  ITaskCreate,
  TTask,
} from '../../screens/types';

export const tasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOpenTasks: builder.query<IResponseTasks, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_OPEN_TASKS_ENDPOINT,
        method: 'GET',
      }),
    }),
    getOpenTask: builder.query<IOpenTask, string>({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}${id}/`,
        method: 'GET',
      }),
    }),
    getNavigationTask: builder.query<IResponseNavigation, string>({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_NAVIGATION_TASK_ENDPOINT}?id=${id}`,
        method: 'GET',
      }),
    }),
    getCloseTasks: builder.query<IResponseTasks, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_COMPLETED_TASKS_ENDPOINT,
        method: 'GET',
      }),
    }),
    getCarsharingList: builder.query<IResponseCarsharingList, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_CARSHARING_ENDPOINT,
        method: 'GET',
      }),
    }),
    getTypesByCarsharing: builder.query<IResponseTaskTypesList[], string>({
      query: (carsharing) => ({
        url: `${API_ENDPOINTS.GET_TYPES_BY_CARSHARING_ENDPOINT}?carsharing=${carsharing}&source=web`, //TODO: change app to web
        method: 'GET',
      }),
    }),
    getCarsByCarsharing: builder.query<IResponseCarsByCarsharingList, string>({
      query: (carsharing) => ({
        url: `${API_ENDPOINTS.GET_CARS_BY_CARSHARING_ENDPOINT}?carsharing=${carsharing}`,
        method: 'GET',
      }),
    }),
    getFieldListByCarsharingAndTypes: builder.query<
      IResponseFieldListByCarsharingAndTypes,
      { carsharing: string; task_type: string }
    >({
      query: ({ carsharing, task_type }) => ({
        url: `${API_ENDPOINTS.GET_FIELD_LIST_BY_CARSHARING_AND_TYPE_ENDPOINT}?carsharing=${carsharing}&task=${task_type}`,
        method: 'GET',
      }),
    }),
    getFieldToDrawerTask: builder.query<
      IResponseFieldListToDrawer,
      { carsharing?: string; task_type?: string; id?: string }
    >({
      query: ({ carsharing, task_type, id }) => ({
        url: `${API_ENDPOINTS.GET_FIELD_LIST_TO_DRAWER_ENDPOINT}?carsharing=${carsharing}&task=${task_type}&task_id=${id}`,
        method: 'GET',
      }),
    }),
    postDeleteImage: builder.mutation<void, IDataPostDelete>({
      query: ({ ids, apiLink }) => ({
        url: `${apiLink}delete/`,
        method: 'POST',
        body: { ids: ids },
      }),
    }),
    postDeleteTask: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}delete/`,
        method: 'POST',
        body: { ids: id },
      }),
    }),
    postCreateTask: builder.mutation<TTask, ITaskCreate>({
      query: (task) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}`,
        method: 'POST',
        body: task,
      }),
    }),
    postUploadImage: builder.mutation<void, IImageUpload>({
      query: ({ apiLink, formData }) => ({
        url: `${apiLink}`,
        method: 'POST',
        body: formData,
      }),
    }),
    updateTask: builder.mutation<
      void,
      {
        key:
          | 'mileage_before_task'
          | 'mileage_after_task'
          | 'status'
          | 'photobeforetransit_set'
          | 'photoaftertransit_set'
          | 'photobeforewash_set'
          | 'photoafterwash_set'
          | 'photobeforecleaning_set'
          | 'photoaftercleaning_set'
          | 'photobeforemileage_set'
          | 'photoaftermileage_set'
          | 'photobeforetire_set'
          | 'photoaftertire_set'
          | 'photototal_set'
          | 'photodamage_set'
          | 'photonavigator_set'
          | 'mileage_before_task'
          | 'mileage_after_task'
          | 'antifreeze_liquid_liters_new'
          | 'transit_season_tire_before'
          | 'car_condition'
          | 'car_condition_comment'
          | 'transit_season_tire_after';
        value: string;
        id: string | undefined;
      }
    >({
      query: ({ key, value, id }) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}` + `${id}/`,
        method: 'PATCH',
        body: { [key]: value },
      }),
    }),
  }),
});

export const {
  usePostUploadImageMutation,
  usePostDeleteImageMutation,
  useUpdateTaskMutation,
  usePostDeleteTaskMutation,
  usePostCreateTaskMutation,
  useGetOpenTaskQuery,
  useGetOpenTasksQuery,
  useLazyGetFieldToDrawerTaskQuery,
  useGetCloseTasksQuery,
  useGetNavigationTaskQuery,
  useGetCarsharingListQuery,
  useLazyGetTypesByCarsharingQuery,
  useLazyGetCarsByCarsharingQuery,
  useLazyGetFieldListByCarsharingAndTypesQuery,
} = tasksApi;

export const {
  endpoints: { getOpenTasks, getCloseTasks },
} = tasksApi;
