import * as React from 'react';
import { Badge, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { LuClipboardList, LuUserCircle2 } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

interface IBottomNavigationProps {
  count: number;
}

const BottomNavigationComponent: React.FC<IBottomNavigationProps> = (badge) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState('');
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    // Использование pathname для корректного отображения выбранной вкладки в Bottom Navigation Bar
    if (window.location.pathname === '/') {
      setValue('');
    } else if (window.location.pathname.includes('tasks/')) {
      setValue('tasks/');
    }
  }, [window.location.pathname]);

  React.useEffect(() => {
    if (window.location.pathname.includes('/task/')) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [window.location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(`/${newValue}`);
  };

  return (
    visible && (
      <>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            value={value}
            onChange={handleChange}
            sx={{
              color: 'inherit',
              height: '64px',
            }}
          >
            <BottomNavigationAction
              label="Обзор"
              data-cy="navigation-bar-overview"
              value=""
              icon={<LuUserCircle2 size={20} />}
            />
            <BottomNavigationAction
              label="Задачи"
              data-cy="navigation-bar-tasks"
              value="tasks/"
              icon={
                <Badge badgeContent={badge.count} color="primary" max={9}>
                  <LuClipboardList size={20} />
                </Badge>
              }
            />
          </BottomNavigation>
        </Paper>
      </>
    )
  );
};

export default BottomNavigationComponent;
