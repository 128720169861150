import { createListenerMiddleware } from '@reduxjs/toolkit';

import { authApi } from '../redux/services/auth';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: authApi.endpoints.login.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (action.payload.access) {
      localStorage.setItem('access', action.payload.access);
    }
    if (action.payload.refresh) {
      localStorage.setItem('refresh', action.payload.refresh);
    }
  },
});
