import * as React from 'react';
import { Box, Breadcrumbs, Fab, Fade, Link, Toolbar, useScrollTrigger } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { LuChevronUp } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { UI_URLS } from '../../config';

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

const ScrollTop = (props: Props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Fade>
  );
};

const PrivacyPolicyComponent: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    document.title = 'Политика обработки данных | КлинКар';
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.SIGNUP_LINK}>
            Регистрация
          </Link>
          <Typography color="text.primary">Политика обработки данных</Typography>
        </Breadcrumbs>
        <Box
          sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'justify' }}
        >
          <span>Обновлено: 23.11.2023</span>
          <span>
            <strong>
              В соответствии со статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006 №
              152-ФЗ «О персональных данных».
            </strong>
          </span>
          <span>
            <strong>Я, субъект персональных данных, именуемый в дальнейшем Пользователь,</strong> отправляя информацию
            через формы обратной связи (далее, каждая из них и все в совокупности-Форма, Формы) при прохождении
            процедуры регистрации в целях использования сайта{' '}
            <a href="https://app.clean-car.net/">https://app.clean-car.net/</a> и его сервисов (далее - Сайт, Сервис), и
            в дальнейшем при использовании Сайта и/или его Сервисов, выражаю полное, безоговорочное и однозначное
            Согласие на обработку моих персональных данных (далее - Согласие) на следующих условиях:
          </span>
          <ol>
            <li>
              <strong>
                Согласие выдано Обществу с ограниченной ответственностью «КлинКар» (ООО «КлинКар», ИНН 7702464454, ОГРН
                1197746158752),
              </strong>{' '}
              расположенному по адресу: 127273, город Москва, Нововладыкинский пр-д, д. 12а, этаж 3 пом 5
              (далее-Оператор).
            </li>
            <li>
              <strong>Согласие выдано на обработку персональных и иных данных,</strong> указанных Пользователем в Формах
              путем заполнения соответствующих текстовых полей и/или прикрепленных к Формам файлов, а именно следующих
              категорий:
              <ul>
                <li>
                  фамилия, имя, отчество, адрес электронной почты (e-mail), номер телефона, адрес регистрации, другая
                  аналогичная информация, сообщённая о себе Пользователем Сайта, на основании которой возможна его
                  идентификация как субъекта персональных данных.
                </li>
                <li>
                  данных, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес,
                  информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес
                  предыдущей страницы);
                </li>
                <li>статистики о моих IP-адресах.</li>
              </ul>
            </li>
            <li>
              <strong>Согласие выдано на обработку персональных данных в целях:</strong>
              <ul>
                <li>
                  исполнения соглашений по предоставлению доступа к Сайту, его Содержанию и/или Сервису, к функционалу
                  Сервиса, для администрирования Сайта;
                </li>
                <li>идентификации при регистрации на Сайте и/или при использовании Сервиса;</li>
                <li>оказания услуг, обработки запросов и заявок;</li>
                <li>установления обратной связи, включая направление уведомлений и запросов;</li>
                <li>подтверждения полноты предоставленных персональных данных;</li>
                <li>заключения договоров, осуществления взаиморасчетов;</li>
                <li>сбора Оператором статистики;</li>
                <li>
                  улучшения качества работы Сайта и/или его Сервиса, удобства их использования и разработки новых
                  сервисов и услуг;
                </li>
                <li>
                  проведения маркетинговых (рекламных) мероприятий, направления Оператором предложений и получения их
                  Пользователем для продвижения на рынке услуг Оператора, в том числе, путем осуществления прямых
                  контактов.
                </li>
              </ul>
              <span>
                Пользователь, настоящим, подтверждает, осведомлен и согласен, что для вышеуказанных целей, Оператор
                вправе собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в
                процессе доступа Пользователя к Сайту, его Содержанию и/или Сервису, или от третьих лиц, и включающую в
                себя данные о технических средствах (в том числе, мобильных устройствах) и способах технологического
                взаимодействия с Сайтом и/или его Сервисом (в т. ч. IP-адрес хоста, вид операционной системы
                Пользователя, тип браузера, географическое положение, данные о провайдере и иное), об активности
                Пользователя при использовании Сайта и/или его Сервиса, cookies, об информации об ошибках, выдаваемых
                Пользователю, о скачанных файлах, видео, инструментах, а также иные данные, получаемые установленными
                Правилами обработки ПДн способами; распоряжаться статистической информацией, связанной с
                функционированием Сайта и/или его Сервиса, а также информацией Пользователя для целей организации
                функционирования и технической поддержки Сайта и/или его Сервиса и исполнения условий законодательства
                Российской Федерации, и разработанных в соответствии с ним Правилами обработки ПДн.
              </span>
            </li>
            <li>
              Согласие выдано на обработку персональных данных смешанным (автоматизированным и неавтоматизированным)
              способом.
            </li>
            <span>
              <strong>В процессе обработки персональных данных Оператор вправе осуществлять:</strong> сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
              передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
              персональных данных Пользователя.
            </span>
            <li>
              <strong>Настоящим, Пользователь подтверждает, что:</strong>
              <ul>
                <li>
                  <strong>
                    Ознакомлен и согласен с тем, что передача персональных данных Пользователя может осуществляться
                    Оператором в объеме,
                  </strong>{' '}
                  необходимом для получения Пользователем доступа к Сайту, его Содержанию и/или его Сервису,
                  определенному третьему лицу или определенному кругу третьих лиц, избираемых Оператором самостоятельно.
                </li>
                <li>
                  <strong>Дает согласие на обработку своих персональных данных,</strong> указанных в пункте 2 настоящего
                  согласия, лицам, указанным в пункте 1 настоящего согласия, а также подтверждает о разъяснении
                  Оператором последствий непредоставления персональных данных, в случаях, когда предоставление таких
                  данных является обязательным в соответствии с федеральным законом.
                </li>
                <li>
                  <strong>Проинформирован о возможности отзыва согласия</strong> на основании положений Федерального
                  закона от 27.07.2006 № 152-ФЗ «О персональных данных» путем личного обращения или направления
                  письменного обращения (в том числе в форме электронного документа, подписанного простой электронной
                  подписью или усиленной квалифицированной электронной подписью), на имя лиц, указанных в пункте 1
                  Согласия, в том числе проинформирован о праве отозвать Согласие в целях прекращения обработки лицом,
                  указанным в пункте 1 Согласия, как всех указанных в пункте 2 Согласия персональных данных, так и
                  отдельно биометрических персональных данных (если таковые будут запрошены Оператором и предоставлены
                  Пользователем).
                </li>
                <li>
                  <strong>
                    Проинформирован, что лица, указанные в пункте 1 настоящего Согласия, вправе продолжать обработку
                    персональных данных
                  </strong>{' '}
                  Пользователя после получения отзыва Согласия, а равно после истечения срока действия Согласия, при
                  наличии оснований, предусмотренных частью 2 статьи 9 Федерального закона от 27.07.2006 № 152-ФЗ «О
                  персональных данных».
                </li>
                <li>
                  <strong>Дает согласие на получение рекламно-информационных материалов</strong> и ознакомлен Оператором
                  о возможности и порядке совершения отказа от таковой.
                </li>
              </ul>
            </li>
            <li>
              <strong>Согласие вступает в силу с момента его отправки и может быть отозвано Пользователем</strong> путем
              направления письменного заявления в произвольной форме в адрес Оператора по адресу электронной почты{' '}
              <a href="mailto:support@clean-car.net">support@clean-car.net</a> либо иным способом, позволяющим
              однозначно определить факт его получения адресатом.
            </li>
            <li>
              <strong>Согласие действует в течение неопределенного срока до момента его отзыва Пользователем.</strong>{' '}
              Согласие прекращает свое действие с даты, указанной в заявлении Пользователя об отзыве Согласия на
              обработку ПДн, но не ранее даты, следующей за датой фактического получения Оператором отзыва Согласия.
            </li>
            <li>
              <strong>Датой и временем формирования, подтверждения и отправки Согласия</strong> прошу считать момент
              маркировки соответствующего поля (либо нажатия на соответствующую кнопку) в Форме и/или нажатие на кнопку
              отправки Формы на любой странице Сайта и/или его Сервиса.
            </li>
            <li>
              <strong>
                Руководствуясь ч. 4 ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»,
              </strong>{' '}
              направляю через личное мобильное устройство или персональный компьютер путем прикрепления к специальной
              форме или при заполнении соответствующих полей такой формы при прохождении процедуры регистрации на Сайте
              свои персональные данные.
            </li>
            <li>
              <strong>
                Пользователь проинформирован, что ответственность перед ним за действия лица, обрабатывающего его
                персональные данные по поручению Оператора, несет Оператор, выдавший соответствующее поручение
                (согласие).
              </strong>
            </li>
          </ol>
        </Box>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <LuChevronUp />
          </Fab>
        </ScrollTop>
      </Box>
    </Container>
  );
};

export default PrivacyPolicyComponent;
